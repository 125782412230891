<template>
    <div class="pitanja">
        <section class="app-user-list">
            <div class="card" v-if="!loading">
                <div class="card-datatable table-responsive pt-0">
                    <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div class="d-flex justify-content-between align-items-center header-actions mx-1 row mt-75">
                            <div class="col-lg-12 col-xl-6">

                            </div>
                            <!-- <div class="col-lg-12 col-xl-6 pl-xl-75 pl-0">
                                 <div class="dt-action-buttons text-xl-right text-lg-left text-md-right text-left d-flex align-items-center justify-content-lg-end align-items-center flex-sm-nowrap flex-wrap mr-1">
                                     <div class="mr-1">
                                         <div id="DataTables_Table_0_filter" class="dataTables_filter"><label>Pretraga:<input type="search" class="form-control" placeholder="" aria-controls="DataTables_Table_0"></label>
                                         </div>
                                     </div>
                                     <div class="dt-buttons btn-group flex-wrap">
                                         <button class="btn add-new btn-primary mt-50" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-toggle="modal" data-target="#modals-slide-in">
                                             <span>Dodaj Novog</span>
                                         </button>
                                     </div>
                                 </div>
                             </div> -->
                        </div>
                        <table class="user-list-table table dataTable no-footer dtr-column" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
                            <thead class="thead-light">
                            <tr role="row">
                                <th class="control sorting_disabled" rowspan="1" colspan="1" aria-label="">ID</th>
                                <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"  aria-label="User: activate to sort column ascending">Ime i Prezime</th>
                                <th class="sorting_desc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-sort="descending" aria-label="Email: activate to sort column ascending">Email</th>
                                <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Role: activate to sort column ascending">Bodovi</th>
                                <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" aria-label="Plan: activate to sort column ascending">Tip Testa</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="odd" v-for="test in testovi " v-bind:key="test.id" v-bind:class="{ 'incorrect': !test.polozio, 'correct': test.polozio }" v-on:click="Pregled(test.test_id,test.user_id)">
                                <td valign="top" colspan="1">{{test.id}}</td>
                                <td valign="top" colspan="1">{{test.ime+' '+test.prezime}}</td>
                                <td valign="top" colspan="1">{{test.email}}</td>
                                <td valign="top" colspan="1">{{test.bodovidOsvojeni}}</td>
                                <td valign="top" colspan="1">{{test.type}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- Modal to add new user starts-->
                <!-- Modal to add new user Ends-->
            </div>
            <!-- list section end -->
        </section>
    </div>
</template>
<script>
    import api from '../api'
  export default {
    name: 'pitanja',
      components: {
      },  data() {
          return {
              testovi:null,
              loading:true,
              serverRoot:process.env.VUE_APP_SERVER_ROOT,
          }
      },
      mounted(){
          this.getAllTestovi().then(data => {
              this.testovi=data.data.Tests;
              this.loading=false;
          });
      },
      methods:{
          async getAllTestovi() {
              return api.getAllTests();
          },
          Pregled(TestId,UserId)
          {
              this.$router.push({ name: 'TestoviPregled', params: { id: TestId,uid:UserId } })
          }
      }
  }
</script>
<style lang="scss">
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }
    tr.odd.incorrect {
        background: red;
        color: white;
        font-weight: bold;
    }
    tr.odd.correct {
        background: green;
        color: white;
        font-weight: bold;
    }
</style>
